// extracted by mini-css-extract-plugin
export var mobileSlideshow = "heroSlider-module--mobileSlideshow--XbMk3";
export var slideshowSlider = "heroSlider-module--slideshowSlider--Ob+XM";
export var slide = "heroSlider-module--slide--3tLwT";
export var sliderGroup = "heroSlider-module--slider-group--5twAG";
export var contentGroup = "heroSlider-module--content-group--6o6cu";
export var title = "heroSlider-module--title--WF+dg";
export var description = "heroSlider-module--description--eHoSQ";
export var slideshowDots = "heroSlider-module--slideshowDots--7nC8T";
export var slideshowDot = "heroSlider-module--slideshowDot--6J9Yz";
export var active = "heroSlider-module--active--jm2Ks";