import * as React from "react";
import PropTypes from "prop-types";
import * as styles from "./image.module.scss";

/**
 * Use this anywhere you have an image of fixed height/width
 * The image component returns a picture element with sources that automatically change with the screen resolution.
 * @param {object} image the image object from prismic - the highest level
 * @param {bool} asBackground whether or not to treat the image as a background
 * @returns a picture element that includes sources for all provided breakpoints
 */
const Image = ({
	image,
	asBackground,
	newsroomObjectFit,
	heroImageObjectFit,
	featuredImageObjectFit,
	heroImageBackground,
}) => {
	if (!image && !image.url) return null;
	return (
		<picture>
			{image?.thumbnails?.xs && (
				<source
					media="(max-width:576px)"
					srcSet={image.thumbnails.xs.url}
				/>
			)}
			{image?.thumbnails?.sm && (
				<source
					media="(max-width:768px)"
					srcSet={image.thumbnails.sm.url}
				/>
			)}
			{image?.thumbnails?.md && (
				<source
					media="(max-width:992px)"
					srcSet={image.thumbnails.md.url}
				/>
			)}
			{image?.thumbnails?.lg && (
				<source
					media="(max-width:1200px)"
					srcSet={image.thumbnails.lg.url}
				/>
			)}
			{image?.thumbnails?.xl && (
				<source
					media="(max-width:1400px)"
					srcSet={image.thumbnails.xl.url}
				/>
			)}
			{image?.thumbnails?.xxl && (
				<source
					media="(min-width:1400px)"
					srcSet={image.thumbnails.xxl.url}
				/>
			)}
			<img
				className={`${asBackground ? styles.backgroundImg : ""} ${
					newsroomObjectFit ? styles.newsroomObjectFit : ""
				} ${heroImageBackground ? styles.heroImageBackground : ""}`}
				alt={image.alt}
				src={image.url}
			/>
		</picture>
	);
};

export default Image;

Image.propTypes = {
	image: PropTypes.object.isRequired,
	asBackground: PropTypes.bool,
	newsroomObjectFit: PropTypes.bool,
	heroImageBackground: PropTypes.bool,
};

Image.defaultProps = {
	asBackground: false,
};
