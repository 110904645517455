import * as React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Layout from "../components/layout/layout";

import * as styles from "./indexPage.module.scss";
import HeroImage from "../components/heroImage/heroImage";
import HeroSlider from "../components/heroSlider/heroSlider";
import SliceZone from "../components/sliceZone";
import Seo from "../components/seo";

// markup
const IndexPage = ({ data, location }) => {
	if (!data) return null;
	const doc = data.prismicHomepage.data;

	console.log("doc = > ", doc);

	return (
		<Layout>
			<Seo
				title={doc.meta_title}
				description={doc.meta_description}
				ogImage={doc.og_image?.url}
				location={location}
			/>
			<div className="w-100">
				<div
					style={{
						backgroundImage: `url(${doc?.hero_background_image.url})`,
					}}
					className={`headline-font ${styles.heroImage}`}
				>
					<div className="container">
						<div className="row">
							<div className={`col-sm-6 ${styles.hero}`}>
								<PrismicRichText
									components={{
										paragraph: ({ children }) => (
											<p className={`${styles.heroText}`}>
												{children}
											</p>
										),
										heading2: ({ children }) => (
											<h2
												className={`${styles.heroText}`}
											>
												{children}
											</h2>
										),
										heading4: ({ children }) => (
											<h4
												className={`${styles.heroText}`}
											>
												{children}
											</h4>
										),
									}}
									field={doc?.homepage_hero_text.richText}
								/>
							</div>
							<div className="col-sm-6">
								<HeroSlider data={doc} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<SliceZone sliceZone={doc.body} />
		</Layout>
	);
};

export default withPrismicPreview(IndexPage);

export const query = graphql`
	query IndexPageQuery {
		prismicHomepage {
			data {
				hero_background_image {
					gatsbyImageData(layout: FIXED)
					url
				}
				homepage_hero_slider_group {
					homepage_hero_slider_group_image {
						gatsbyImageData(layout: CONSTRAINED)
						alt
						url
					}
					homepage_hero_slider_group_title
					homepage_hero_slider_group_description
				}
				homepage_hero_text {
					richText
				}
				body {
					... on PrismicHomepageDataBodyHeroImageAndTitle {
						id
						slice_type
						primary {
							hero_background_image {
								gatsbyImageData(layout: FIXED)
								url
							}
							hero_text {
								richText
							}
						}
					}
					... on PrismicHomepageDataBodyAlertBanner {
						id
						slice_type
						primary {
							alert_banner_icon
							alert_banner_text {
								richText
							}
						}
					}
					... on PrismicHomepageDataBodyGeneralContentWithHeadline {
						id
						slice_type
						primary {
							general_content_headline
						}
						items {
							general_content_content {
								richText
							}
						}
					}
					... on PrismicHomepageDataBodyFeaturedProductSection {
						id
						slice_type
						primary {
							featured_product_section_title
						}
						items {
							featured_product_box_image {
								gatsbyImageData(layout: FULL_WIDTH)
							}
							featured_product_box_title
							featured_product_box_description
							featured_product_box_price
							featured_product_box_on_sale_toggle
						}
					}
					... on PrismicHomepageDataBodyCallToAction {
						id
						slice_type
						primary {
							call_to_action_background_image {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
								)
								url
							}
							call_to_action_title {
								richText
							}
							call_to_action_description
							call_to_action_button_text
							call_to_action_button_url {
								url
								link_type
								id
								type
								target
							}
							call_to_action_button_color
						}
					}
				}
				meta_description
				meta_title
				og_image {
					url
				}
			}
		}
	}
`;

IndexPage.propTypes = {
	data: PropTypes.object.isRequired,
};
