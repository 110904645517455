import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { PrismicRichText } from "@prismicio/react";
import Image from "../image/image";
import * as styles from "./heroSlider.module.scss";

const delay = 5500;

const HeroSlider = ({ data }) => {
	const [index, setIndex] = React.useState(0);
	const timeoutRef = React.useRef(0);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	React.useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() =>
				setIndex((prevIndex) =>
					prevIndex === data.homepage_hero_slider_group.length - 1
						? 0
						: prevIndex + 1
				),
			delay
		);

		return () => {
			resetTimeout();
		};
	}, [index]);

	return (
		<div className={styles.mobileSlideshow}>
			<div
				className={styles.slideshowSlider}
				style={{
					transform: `translate3d(calc(${-index * 100}%), 0, 0)`,
				}}
			>
				{data.homepage_hero_slider_group.map((item, i) => (
					<div className={styles.slide} key={Math.random(0, 99999)}>
						<div className={styles.sliderGroup}>
							<div className={styles.image}>
								<GatsbyImage
									image={getImage(
										item?.homepage_hero_slider_group_image
									)}
									className={styles.image}
									alt=""
								/>
								{/* <Image
									fullWidthObjectFit
									image={
										item?.homepage_hero_slider_group_image
									}
								/> */}
							</div>
							<div className={styles.contentGroup}>
								<div className={styles.title}>
									{item?.homepage_hero_slider_group_title}
								</div>
								<div className={styles.description}>
									{
										item?.homepage_hero_slider_group_description
									}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className={styles.slideshowDots}>
				{data.homepage_hero_slider_group.map((_, idx) => (
					<div
						key={Math.random(0, 999999)}
						className={`${styles.slideshowDot}${
							index === idx ? ` ${styles.active}` : ""
						}`}
						onClick={() => {
							setIndex(idx);
						}}
						onKeyPress={() => {
							setIndex(idx);
						}}
						aria-label="slide selector"
						role="button"
						tabIndex={0}
					/>
				))}
			</div>
		</div>
	);
};

export default HeroSlider;

HeroSlider.propTypes = {
	data: PropTypes.object.isRequired,
};
